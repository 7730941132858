import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
const currentKeySystem = 'USD';
const COUNTRY_DATA: { [key: string]: any } = {
  USD: {
    code: 'USD',
    digits: '1.2',
    locale: 'en-US',
    enableCode: false,
    currencyCode: 'USD',
  },
  MXN: {
    code: 'USD',
    digits: '1.2',
    locale: 'en-US',
    enableCode: true,
    currencyCode: 'MXN',
  },
  EUR: {
    code: 'EUR',
    digits: '1.2',
    locale: 'de-DE',
    enableCode: true,
    currencyCode: 'EUR',
  },
  COP: {
    code: 'COP',
    digits: '1.2',
    locale: 'es-CO',
    enableCode: true,
    currencyCode: 'COP',
  },
};
@Pipe({
  name: 'customCurrency',
  standalone: true,
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(
    value: number | string,
    parameters: any = {
      country: currentKeySystem,
      enableCode: COUNTRY_DATA[currentKeySystem].enableCode,
      removeDigits: false,
    }
  ): string {
    if (COUNTRY_DATA[parameters.country]?.locale) {
      const digitsInfo = parameters.removeDigits
        ? '1.0-0'
        : COUNTRY_DATA[parameters.country].digits;
      const formatedCurrency = formatCurrency(
        Number(value),
        COUNTRY_DATA[parameters.country].locale,
        getCurrencySymbol(COUNTRY_DATA[parameters.country].code, 'narrow'),
        COUNTRY_DATA[parameters.country],
        digitsInfo
      );
      return `${formatedCurrency} ${
        parameters.enableCode
          ? COUNTRY_DATA[parameters.country].currencyCode
          : ''
      }`;
    } else {
      return '';
    }
  }
}
